import styled from "styled-components"
import React, { useState } from "react"
import { Section } from "./styles/Sections"

const ToC = ({ headings, flex }) => {

  const [visibility, setVisibility] = useState(false);

  const ToggleToc = () => {
    setVisibility(!visibility)
  }

  return (
    <TocWrapper className={flex}>
      <Section className="wrapper">
        <div className="toggler" onClick={() => ToggleToc()}>Innehållsförteckning: </div>
        {visibility !== false && (
          <ul>
            {headings.map(({ url, title }) => {
              const isCurrentPage = window.location.href.includes(url)
              return (
                <li key={url}>
                  <a aria-current={isCurrentPage ? "page" : undefined} onClick={() => ToggleToc()} href={url}>{title}</a>
                </li>
              )
            })}
          </ul>
        )}
      </Section>
    </TocWrapper> 
  )
}

const TocWrapper = styled.div`
  position: sticky;
  z-index: 90;
  top: 63px;
  background: var(--color-blue);
  display: flex;
  align-items: center;
  height: 60px;

  @media (max-width: 992px) {
    top: 50px;
  }

  .wrapper {
    width: 100%;
    position: relative;
  }
 
  .toggler {
    margin-left: auto;
    background: #fff;
    padding: 8px 15px;
    font-size: 14px;
    border-radius: 8px;
    display: inline-block;
    font-weight: 600;
    color: #000;
    cursor: pointer;
    margin-left: 20px;
  }

  ul {
    background: #0d207e;
    position: absolute;
    left: 20px;
    top: 60px;
    margin: 0;
    list-style-type: decimal;
    padding: 10px 25px 15px 40px;
    border-radius: 8px;

    @media (max-width: 992px) {
      left: 15px;
      right: 15px;
    }

    li {
      margin: 8px 0;

      &::marker {
        color: var(--color-yellow);
        font-size: 16px;
      }
    }
  }

  a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 15px;
    font-weight: var(--fontWeight-medium);

    &:hover {
      color: var(--color-yellow);
    }

    &[aria-current="page"], &:hover {
      color: var(--color-yellow);
    }
  }

  &.flex {
    background: var(--color-blue-dark);
    padding: var(--spacing-2);
    position: sticky;
    top: 65px;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;

    @media (max-width: 1200px) {
      overflow: scroll;
      white-space: nowrap;
      justify-content: left;
      top: 0;
    }

    h2 {
      margin: 2px 15px 0 0;
      font-size: var(--fontSize-0);
    }

    ul {
      display: flex;
      align-items: center;
      grid-gap: 25px;
      justify-content: center;

      li a {
        font-weight: var(--fontWeight-medium);
        font-size: 14px;
  
        &[aria-current="page"], &:hover {
          color: var(--color-yellow);
        }
      }
    }

  
  }
`
export default ToC;